.card-person {
  width: 335px;
  height: 580px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.header-person-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.title-person {
  height: 90px;
  width: 90px;
  border-radius: 10px;
  /* border: 2px solid rgba(255, 255, 255, 0.5); */
  position: relative;
}

.title-person img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.wrapper-name {
  text-transform: uppercase;
}

.wrapper-name span {
  color: rgb(218, 216, 216);
  font-size: 17px;
  font-weight: 600;
}

.contents-person {
  height: 68%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  background-color: #303030;
  border-radius: 30px;
  padding: .7rem;
  padding-top: 1rem;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.contents-person:hover {
  mask-image: none;
  -webkit-mask-image: none;
}

.social-media {
  height: 6%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: white;
}

.icon-sosial {
  flex: 1;
}

.social-media .icon-sosial:hover,
.active {
  cursor: pointer;
  color: tomato;
}

.numfound-wrapper {
  height: 7%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: white;
}

.numfound-wrapper .numfound {
  flex: 1;
  font-weight: 600;
  font-size: 20px;
  font-family: "IBM Plex Sans";
}

.content-article {
  background-color: #444444;
  /* height: 0%; */
  width: 99%;
  overflow-y: auto;
  border-radius: 25px;
  padding: .7rem 1rem;
  margin-top: 1rem;
}

.content-article .article {
  color: white;
  border-bottom: 1px dashed #B5B3B3;
  padding: 5px 0;
  font-family: "Roboto";
  font-weight: 300;
  margin-bottom: 7px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.3s ease;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content-article .article:hover {
  cursor: pointer;
  color: tomato;
}

a {
  text-decoration: none;
  color: rgb(21, 31, 165);
}
a:hover {
  color: rgb(230, 230, 230);
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(243, 243, 243, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 35, 53);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 61, 92);
}
